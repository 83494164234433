import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";
import { graphql, useStaticQuery, navigate } from "gatsby";
import Img from "gatsby-image";
import Social from "components/Social";
import { useEffect, useState } from "react";
import useSWR from "swr";

import bitcoinMessage from "bitcoinjs-message";

import Content from "./styled";
import withLocation from "components/withLocation";

import * as V from "styles/variables";
import * as T from "styles/typography";
import * as S from "./styled";

const PGP = ({}) => {
  return (
    <Content>
      <div>
        <p>
          -----BEGIN PGP PUBLIC KEY BLOCK-----
          <br />
          <br />
          xsBNBGJONPIBCAC7i6m8tyMOeCBuGQGKSw+mbwQOTyDTtCMofkqmoRXGnBA+9tIz
          GYkKnXX9vaQdWugUxym08y0SDpRitkgJ0/OM/oAmlYDRgF0kG8QOVdyRoKvGdmnw
          7EvUoVmatS75khuBg0qzUHn569z5CQAtYctJb458Q/lTRY9G/EUrGL/ubbe9djwZ
          QEM+qabOe6vSI+QRApPLjt2y/nDj8O3vF4d+9/kZCjLv35MzDeglu5mhHxOAdj/O
          2CJ9itZA0vSx5XQboIoe4iFz5ArqLhV2OcKJiu5xiNxh9tgbTRzy2H4dRIabRW1j
          drwcPUGPOE/ek4nW35kHV3w2Teak4mPGZXwbABEBAAHNJ1NVSEFJTCBFIFNBUUFO
          IDxzdWhhaWwuc2FxYW5AZ21haWwuY29tPsLAbQQTAQoAFwUCYk408gIbLwMLCQcD
          FQoIAh4BAheAAAoJEO22M943szmPAOYH+wbu6L6FnFsuZ2iKrXMSlwLEs//aXndD
          TKDzuDeCYJ3L98RKUPQhpHgWA+DclmcMSiUpjL+ILoScEOeDBC+K7eYonlWuWGrH
          Od9B2h0R+dNkHtXXQNUJIzeirH9zGLRikhNZ3MpsOc+1BZtViaPOHNVHefEbxcyy
          3A5Sg9erYsaSb4/QiORxOKwrKNW7dn2arT4VU8W0mAEDj/hln+z6Fq1CVheLbK4e
          qCvu2rN/p45luAR8lJc/DvMvidBfcI38Mug1NjMGP6XCsExWpy/2jSnbNCTze5Xu
          3KPAL0dPPyRJWASM8hmWt8+nxZGHIDzDKADdPeFEnhLk1kjgdChVhj7OwE0EYk40
          8gEIAKp8HEfXGPJS39z7Wp3yZ6hjUd8ZXzHelhZf3Hin/lPpitSvtH52UNJ14FAl
          w71HssHSRfaAeOyoxS/VDOSolC489Tu46YkfbzXCp0MBwuFqSJqGgiNmQQhjv122
          Vg3dEv4m+iSAMz/nyt55jZe0DCn0GM1l/kG2fn1YHrm+S4ZOMPVdYK6XlovXW2y/
          JvDWdtr7vorrmBk2GX77AD5CZRhEt132dCLFqBnvbiIDmePfzWNRtigL/MdMAa5g
          Y6VlidA9J7OIEiFfb9KDEAk+wXQ2Q4tfmv6sOg6gQHNrBOrHCuKqq+DHFY7g1Kny
          V4JxWIyzpt7t1rKTtDA9PplKDA8AEQEAAcLBhAQYAQoADwUCYk408gUJDwmcAAIb
          LgEpCRDttjPeN7M5j8BdIAQZAQoABgUCYk408gAKCRAaFWCtEicVznPhCACQmK66
          xJz0LF4idlC2jwyiuEL4ZoQK4btyJIvbkVry4Eoo2tQFnmwbnhjLdoA1mUwscZUS
          lR1LGyTPJsOfFPwlUdpcqYubUw3NVKdFimGDnNamt9TR888HRkBQFzZRL6C4Vql5
          7LgltsaqKTX0bCcOt7kwMoW9rAfvsmYen6lcnj/uaFCYrSWGMv3eV9bpxQz1sJRZ
          n7ObsgTASqcAQ+POYt2KjjHdqzs7+4G+rnT/1Ay/kaQ8SKFtbL7ahah8VvrUgAU3
          iuZbUVzpbg/6oPNy7Tl0nWRZdkDf5wL7NDkEwD/ym885yyu4qNaDw7G3in1Tc8Nk
          33Iou4F+gI06UVZthVwH/0/vN4uJ/gU3YLJjEZtWJ6oxMNS4iFnsHgmWLbn9rFQe
          UeyNGjWimIE+eVm4mpJK7VJK8r+VeCioAcHOw7bGC+EvH8Tl1BICuVnJglXL1w5g
          d5Nz8v48jvnfsB+fhFhv8zOp06gyP/wy56sLFHlvTlw69jHnXt4ZDf+ByrOPvSup
          7xSyeqc33UdG0Gz9doU/Hi786a02dilLYtowN+bd7FwSWQdoJtxEka/mCCgxPtRZ
          WJlEk6l4ZtC++KHlYSguku1/AMloOYL34IN79h3ciMKu0cAt87eQxPELOxM1Gvhc
          Co2WljX4HhFhcvp0yfNa0jUc9KUfX7ma4/JOZRDlpuTOwE0EYk408gEIALMP5x4T
          YhEeiX24cw5WND0WuOzA7MZJnoTj0njRNhgAUGw/1YDIdeOk7Y1/dE6rTJMPLSCn
          si7WZKWrkTXF1WnHD2s5DvFsMHzACK7N+KJaoECXqz7pnhrPOkn13qSh+uTWRMZo
          r5qfAOU/hE6qeIePgfZOfSUrwfzWD6oedwLqt5EOLLQF9MSKEQm4otYEcArA0z/3
          ni2vYn+d77edPlgPIc6ElvT06mGPa2cY2/JoBh/WBnsuRBbA+GkYpTiKEy+oZU5u
          HAzOjZWkzUVQCswfyrghAVVVEUkPddFRP8hvHzc1W340hxAqwv0eqEMRPCgfGEug
          pFasBD4rwzZ40xsAEQEAAcLBhAQYAQoADwUCYk408gUJDwmcAAIbLgEpCRDttjPe
          N7M5j8BdIAQZAQoABgUCYk408gAKCRCj2yyoDeEgHXpfB/9yoA2vi6PoYXncHujg
          eNQwM1Db+ipYEy5cCNKrGUM3eOZGFjRf7obdlObOF2lrco4HHzRzdC6fQBZUZFrv
          Xkf0gvSSRMhwDeUZgolLQurIyoXk1TBn77/JWfPmfIywkKLrkC7lcMhnvTKustHz
          EaIRiaMLcq6TBXcJwa44nntZBMgihUF/QvV/mUSlf4v6mScDNhefObaNStOegWu4
          vOtmfGUiM0frP17opSuabO3WEXERhnFygE33awVEg7DxD1Sh8WCag9zPpdbfHoKj
          dPvmpVyItIxRDw34cKYsctdQG8S8m3zUpHsbwCJcUeRBySGWaXDd4F8H4NXHzr50
          +8KeWbkH/1tE2V0OY9U+w2HEPR5iNz7CekJXFR3vlwmKeKbIwIVVHznAudPJ1JxD
          C4yQc8LJ0R3lcRO4JTjZ38ZtC+a/dk3BmEj4zX8kgoIjC1y2Z7853A0eAfMhCbq5
          3FyDvp88Ls5wJ4amTWvKopH6JMab4VujYhUuLGaIlebLGvcXuU5TU4ednNFNVZge
          H3IuJyQej0fRTYyVmGfb1fRsXB2WTX8R2tCIN1wP1puQJjgkk21Mt2CzW81v1vRH
          h64t4zo6L0jKQ8lAXJghBECQ4I8z3DkzuezqgHY4yuqFdh1jZRkTEDQQDgzE1pae
          WUAtKNRf3jEEYa94M0X9yZWl9IYix1E= =47zV
          <br />
          -----END PGP PUBLIC KEY BLOCK-----
        </p>
      </div>
    </Content>
  );
};

export default withLocation(PGP);
